import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import styles from './Section/VFSectionV2.module.css';
import { FIELD_TYPES } from "@ais/constants";
import {
  FreeText,
  LongAnswer,
  ShortAnswer,
  DropDown,
  RadioButtonList,
  CLADatePicker,
  Checkbox,
  Table,
  QuestionGroup,
  Formula
} from '@ais/forms';
import ProcedureField from './ProcedureComponents/ProcedureField/ProcedureField';
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper";
import { useOthers } from "@components/Concurrency/store/users";
import { useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useQuery } from '@hooks/index';
import { useFinalizedProjectNoId } from '@hooks/useProject';
import { useProjectFormInstanceProviderV2 } from '@providers';
import useRules from './hooks/useRules'; 
import { LinkIconComponent } from '@ais/assets'; 
import { VFInnerSection } from '@components/FormView/v2/Section';

const StyledColumnField = styled('div')(({ width }) => ({
  position: 'relative',
  padding: '32px',
  width: width,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

const StyledLinkIcon = styled('div')(({ isLink }) => ({
  display: isLink ? 'flex' : 'none',
  cursor: 'pointer',
  marginLeft: '5px',
  width: 'fit-content',
  height: 'fit-content',
}));

const StyledFieldWrapper = styled('div')(({ isHighlight }) => ({
  borderStyle: isHighlight ? 'dashed' : '',
  borderWidth: isHighlight ? '4px' : '',
  borderColor: isHighlight ? '#49bfc1' : '',
  padding: isHighlight ? '8px' : '',
  width: '100%'
}));

export const NATGFieldSelector = React.memo((props) => { 
  const { field, fieldIndex, rowIndex, auditAreaId, sectionId } = props;

  const query = useQuery();
	const isProjectFinalized = useFinalizedProjectNoId();

  const { projectForm, answerList, questionsWithLink, userId,
    actions: { onLinkClick, onSubmit, onFocus } } = useProjectFormInstanceProviderV2();
  const frameworks = useMemo(() => (
    projectForm.frameworks ? projectForm.frameworks.map(f => f.FrameworkId) : []
  ), [projectForm.frameworks]);

  const performanceStandards = useMemo(() => (
    projectForm.performanceStandards ? projectForm.performanceStandards.map(ps => ps.PerformanceStandardId) : []
  ), [projectForm.performanceStandards]);

  const { shouldDisplay } = useRules(field.rules, answerList, projectForm.methodologyIndustries, frameworks, performanceStandards);
  const others = useOthers();
  const updateMyPresence = useUpdateMyPresence();

  if (!shouldDisplay) {
    return <></>;
  }

  const questionIds = query.get('questionIds');
  const lockingUser = others.find((user) => user.presence.focusedId === field.id && user.info.userId.toLowerCase() !== userId.toLowerCase())
  const isHighlight = () => {
    return questionIds?.split(',').includes(field.id) && questionIds?.split(',').indexOf(field.id) >= 0
  }
  let questionId = field.id
  const isQuestionGroup = field.id.includes('.')
  if(isQuestionGroup) questionId = questionId.split('.')[0]
  const lockField = () => { updateMyPresence({ focusedId: questionId }) };
  const unlockField = () => { updateMyPresence({ focusedId: null }) };

  const disabled = isProjectFinalized || 
    field.linkType === 'Read Only' || 
    lockingUser !== undefined || 
    others.find(user => user.presence.focusedId === questionId  && user.info.userId.toLowerCase() !== userId.toLowerCase()) !== undefined
  
  const renderFields = () => {
    switch (field.type) {
      case FIELD_TYPES.FREETEXT:
      case FIELD_TYPES.FREETEXT_NO_RULES:
        return <FreeText {...props}/>
      case FIELD_TYPES.FORMULA:
        return (
          <Formula 
            {...props} 
            disabled={disabled}
            answerList={answerList}
          />
        );
      case FIELD_TYPES.LONG_ANSWER:
        return <LongAnswer
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
        />
      case FIELD_TYPES.CHECKBOXGROUP:
        return <Checkbox
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
        />
      case FIELD_TYPES.DATE_PICKER:
        return <CLADatePicker
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
        />
      case FIELD_TYPES.RADIOGROUP:
        return <RadioButtonList
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onFocus={onFocus}
          onSubmit={onSubmit}
        />
      case FIELD_TYPES.DROPDOWN:
        return <DropDown
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          answerList={answerList}
          onFocus={onFocus}
          onSubmit={onSubmit}
        />
      case FIELD_TYPES.SHORT_ANSWER:
        return <ShortAnswer
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
        />
      case FIELD_TYPES.TABLE:
        return <Table
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
        />
      case FIELD_TYPES.QUESTION_GROUP:
        return <QuestionGroup
          {...props}
          disabled={disabled} 
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
          fieldWrapper={VFInnerSection}
        />
      case FIELD_TYPES.CLIENT_SITUATIONS:
        return <ProcedureField
          auditAreaId={auditAreaId}
          field={field}
          disabled={disabled}
          handleOnFocus={onFocus}  
          sectionId={sectionId}
        />
      default:
        return React.Fragment;
    }
  }
  return (

    <StyledColumnField width={`${field.width}%`} key={`field-row-${rowIndex}-${fieldIndex}`}>
      <StyledFieldWrapper isHighlight={isHighlight()}>
        <VFRenderedFieldWrapper
          className={styles.field__wrapper}
          isLockedByUser={lockingUser}
          isLocked={lockingUser}
        >
          {renderFields()}
        </VFRenderedFieldWrapper>
      </StyledFieldWrapper >
      <StyledLinkIcon isLink={questionsWithLink?.includes(field.id)}>
        <LinkIconComponent onClick={() => onLinkClick(field.id)} />
      </StyledLinkIcon>
    </StyledColumnField>
  )
})