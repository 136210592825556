import {FIELD_TYPES} from "./formdesigner";

const {KPI_TRIAL_BALANCE} = FIELD_TYPES;

export const RESERVED_QUESTION_IDS = {
    KPI: ['64aae4ef-3e4d-4feb-934b-f8537c1a1d6e']
};

export const CUSTOM_FORM_FIELD_TYPES = {
    KPI: [KPI_TRIAL_BALANCE]
};