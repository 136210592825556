import React, { memo } from 'react';
import { styled } from '@mui/system';

import { CUSTOM_FORM_FIELD_TYPES } from "@ais/constants";
import { ClientExternalIcon } from '@ais/forms';
import { KPIFieldSelector, NATGFieldSelector } from '@components/FormView/v2';
import { useProjectFormInstanceProviderV2 } from '@providers';

import styles from './VFSectionV2.module.css';

const StyledRow = styled('div')({
  display: 'flex',
  position: 'relative',
  flexFlow: 'row',
  flexWrap: 'wrap',
  width: '100%'
});

const VFInnerSection = ({ fields, auditAreaId, sectionId}) => {
  const { projectForm: { clientProjectFormStatusId } } = useProjectFormInstanceProviderV2();
  const {KPI} = CUSTOM_FORM_FIELD_TYPES;

  return (
    <div className={styles['vf-section-inner']}>
      {fields.map((row, rowIndex) =>
        <StyledRow key={`row-${rowIndex}`}>
          {row.map((field, fieldIndex) => {
            if (KPI.includes(field.type))
              return (
                <KPIFieldSelector field={field} />
              );
            return (
              <NATGFieldSelector
                field={field}
                rowIndex={rowIndex}
                fieldIndex={fieldIndex}
                auditAreaId={auditAreaId}
                sectionId={sectionId}
                clientExternalIcon={
                  <ClientExternalIcon
                    allowExternalAccess={field.visibleToClient ?? false}
                    answerable={field.editableByClient ?? false}
                    answered={clientProjectFormStatusId === 3}
                  />
                }
              /> 
            )
          })}
        </StyledRow>
      )}
    </div>
  );
}

export default memo(VFInnerSection);
